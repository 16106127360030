import styled from "@emotion/styled";

export const TabsContainer = styled.div`
  margin-top: 0.5rem;
  padding: 0 1.5rem;
  border-bottom: 1px solid var(--mb-color-border);
`;

export const ContentContainer = styled.div`
  margin-top: 1.5rem;
  padding: 0 1.5rem;
`;
